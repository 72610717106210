import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import mainSlice from './slices/mainSlice';
import { createWrapper } from 'next-redux-wrapper';
import { Action } from 'redux';
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
const makeStore = () =>
  configureStore({
    reducer: {
      main: mainSlice.reducer,
    },
    devTools: true,
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

// Always use this hook instead of the plain useSelector
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const wrapper = createWrapper(makeStore);
