import React, { useState } from 'react';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  React.useEffect(() => {
    if (window) window.addEventListener('scroll', toggleVisible);
  }, []);

  return (
    <div
      onClick={scrollToTop}
      style={{
        display: visible ? 'flex' : 'none',
        position: 'fixed',

        right: '50px',
        bottom: '80px',
        fontSize: '3rem',
        zIndex: '9001',
        cursor: 'pointer',
        color: 'green',
        backgroundColor: 'gray',
        borderRadius: '100px',
        width: '50px',
        height: '50px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ArrowCircleUpIcon sx={{ fontSize: '40px', color: '#fff' }} />
    </div>
  );
};

export default ScrollButton;
