import {AnyAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createClient } from 'contentful';
import axios from 'axios';

const servicesSlice = createSlice({
  name: 'main',
  initialState: {
    entries: [],
    assets: [],
    objects: [],
    soldObjects: [],
    houseAssociationTypes: [],
    coaAssociationTypes: [],
    municipalities: [],
    houseTypes: [],
    biddingInfo: [],
    objectSlugs: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: AnyAction) => {
        return {
          ...state,
          ...action.payload.main
        }
      })
      .addCase(fetchEntries.fulfilled, (state, action) => {
        state.entries = action.payload
      })
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.assets = action.payload
      })
      .addCase(fetchObjects.fulfilled, (state, action) => {
        state.objects = action.payload
      })
      .addCase(fetchSoldObjects.fulfilled, (state, action) => {
        state.soldObjects = action.payload
      })
      .addCase(fetchHouseAssociationTypes.fulfilled, (state, action) => {
        state.houseAssociationTypes = action.payload
      })
      .addCase(fetchCoaAssociationTypes.fulfilled, (state, action) => {
        state.coaAssociationTypes = action.payload
      })
      .addCase(fetchMunicipalities.fulfilled, (state, action) => {
        state.municipalities = action.payload
      })
      .addCase(fetchHouseTypes.fulfilled, (state, action) => {
        state.houseTypes = action.payload
      })
      .addCase(fetchObjectSlugs.fulfilled, (state, action) => {
        state.objectSlugs = action.payload
      })
      .addCase(fetchBiddingInfo.fulfilled, (state, action) => {
        state.biddingInfo = action.payload
      })
      .addCase(fetchDictionaryWordPageBySlug.fulfilled, (state, action) => {
          state.entries = action.payload
      })
  }
});

const client = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
});

export const fetchEntries = createAsyncThunk(
  "main/fetchEntries",
  async (slug) => {
    const query = {
      content_type: "page",
      include: 10
    };

    const query2 = {
      "content_type": "testimonial",
      include: 10
    }

    const query3 = {
      "content_type": "customerMessage",
      include: 10
    }

    const res = await Promise.all([
      client.getEntries(query),
      client.getEntries(query2),
      client.getEntries(query3)
    ]);

    const entries = [
      ...res[0].items,
      ...res[0].includes.Entry,
      ...res[1].items,
      ...res[2].items
    ];

    return entries
  }
)

export const fetchEntriesBySlug = createAsyncThunk(
  "main/fetchEntries",
  async (slug: string) => {
    // TODO: Maybe rework how we load content, maybe we can use this function,
    // TODO: and skip fetchEntries, but we always need to ensure we have all
    // TODO: content we need when navigating between pages.
    // TODO: Kanske skapa en egen action type för att "lägga till" en entry i state istället för att ersätta hela state?

    const promises = []

    // The front page has no slug, querying for pages
    // with slug='' would return all pages, which
    // we don't want
    if (slug) {
      promises.push(client.getEntries({
        "content_type": "page",
        "fields.slug": slug,
        "include": 10
      }))
    }

    if (!slug) {
      promises.push(client.getEntries({
        "content_type": "page",
        "fields.isHomePage": true,
        "include": 5
      }))
    }

    promises.push(client.getEntries({
      "content_type": "testimonial"
    }))

    promises.push(client.getEntries({
      "content_type": "customerMessage"
    }))

    const res = await Promise.all(promises)

    let entries = []

    res.forEach(res => {
      if (res.items) {
        entries = [...entries, ...res.items]
      }
      if (res.includes && res.includes.Entry) {
        entries = [...entries, ...res.includes.Entry]
      }
    })

    return entries
  }
)

export const fetchDictionaryWordPageBySlug = createAsyncThunk(
    "main/fetchDictionaryWordPageBySlug",
  async (slug: string) => {
    const query = {
      "content_type": "dictionaryWordPage",
      "fields.slug": slug
    };

    const res = await client.getEntries(query);

    return [
      ...res.items
    ]
  }
)

export const fetchAssets = createAsyncThunk(
  "main/fetchAssets",
  async () => {
    const query = {
      limit: 1000,
    };

    const assets = await client.getAssets(query);
    return assets.items
  }
)

export const fetchObjects = createAsyncThunk(
  "main/fetchObjects",
  async () => {
    const fetchObjects = await axios.get(`${process.env.NEXT_PUBLIC_OBJECTS_URL}`);
    return fetchObjects.data
  }
)

export const fetchSoldObjects = createAsyncThunk(
  "main/fetchSoldObjects",
  async () => {
    const fetchSoldObjects = await axios.get(`${process.env.NEXT_PUBLIC_SOLD_OBJECTS_URL}`);
    return fetchSoldObjects.data
  }
)

export const fetchHouseAssociationTypes = createAsyncThunk(
  "main/fetchAssociationTypes",
  async () => {
    const fetchAssociationTypes = await axios.get(
      `https://static2.privatmaklaren.se/db/houseAssociationTypes.json`
    );
    return fetchAssociationTypes.data
  }
)

export const fetchCoaAssociationTypes = createAsyncThunk(
  "main/fetchCoaAssociationTypes",
  async () => {
    const fetchCoaAssociationTypes = await axios.get(
      `https://static2.privatmaklaren.se/db/coaAssociationTypes.json`
    );
    return fetchCoaAssociationTypes.data
  }
)

export const fetchMunicipalities = createAsyncThunk(
  "main/fetchMunicipalities",
  async () => {
    const fetchMunicipalities = await axios.get(
      `https://static2.privatmaklaren.se/db/municipalities.json`
    );
    return fetchMunicipalities.data
  }
)

export const fetchHouseTypes = createAsyncThunk(
  "main/fetchHouseTypes",
  async () => {
    const fetchHouseTypes = await axios.get(
      `https://static2.privatmaklaren.se/db/houseTypes.json`
    )
    return fetchHouseTypes.data
  }
)

export const fetchObjectSlugs = createAsyncThunk(
  "main/fetchObjectSlugs",
  async () => {
    const fetchObjects = await axios.get(`${process.env.NEXT_PUBLIC_OBJECTS_URL}`);
    return fetchObjects.data.map((item) => `/${item.Slug}`)
  }
)

export const fetchBiddingInfo = createAsyncThunk(
  "main/fetchBiddingInfo",
  async () => {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_ENDPOINT}v2/bidding/info`
    );
    return res.data
  }
)

export default servicesSlice;
