import * as React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import theme from '../styles/theme';
import createEmotionCache from '../../src/lib/createEmotionCache';
import '../styles/global.css';
import { wrapper } from '../redux/store';
import 'core-js/es';
import { useRouter } from 'next/router';
import ScrollButton from '../components/atoms/ScrollButton';
import ProgressBar from '@badrap/bar-of-progress';
import GDPRMsg from "../components/atoms/GDPRMsg";

const progress = new ProgressBar({
  size: 2,
  color: '#3c8',
  className: 'bar-of-progress',
  delay: 100,
});

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const App = (props) => {
  const router = useRouter();

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  React.useEffect(() => {
    router.events.on('routeChangeStart', (url) => {
      progress.start();
    });
    router.events.on('routeChangeComplete', (url) => {
      (window as any).$zopim.livechat.sendVisitorPath({
        url: document.location.href,
        title: document.title,
      });
      progress.finish();
    });
    router.events.on('routeChangeError', progress.finish);
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Privatmäklaren</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...pageProps} />
        <ScrollButton />
        <GDPRMsg />
      </ThemeProvider>
    </CacheProvider>
  );
};

export default wrapper.withRedux(App);
