import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4c735c',
    },
    secondary: {
      main: '#C4C4C4',
    },
    warning: {
      main: '#FBEBAA',
    },
    info: {
      main: '#31394C',
    },
    success: {
      main: '#FFFFFF00',
    }
  }
});

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export default theme;
